import { Injectable } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class DeviceService {
  detect(): void {
    document.body.classList.add(this.isTouch() ? 'touch' : 'no-touch')

    if (this.isIos()) {
      document.body.classList.add('ios')
    } else if (this.isAndroid()) {
      document.body.classList.add('android')
    } else if (this.isWindows()) {
      document.body.classList.add('windows')
    }
  }

  getWindowSize(): { w: number; h: number } {
    return {
      // w: window.innerWidth,
      // h: window.innerHeight
      // this addresses the iOS9 bug when width/height is reported totally wrong (980x1461 on ip6 in safari browser)
      w: document.documentElement.clientWidth,
      h: document.documentElement.clientHeight,
    }
  }

  isTouch(): boolean {
    return 'ontouchstart' in document.documentElement
  }

  isIos(): boolean {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent)
  }

  isAndroid(): boolean {
    return /Android/i.test(navigator.userAgent)
  }

  isWindows(): boolean {
    return /Windows Phone/i.test(navigator.userAgent)
  }

  isMobile(): boolean {
    return this.isAndroid() || this.isIos() || this.isWindows()
  }
}
